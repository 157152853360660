import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/compat/app";

Vue.use(VueRouter);

const routes = [
  { path: "/login", component: () => import("@/views/login") },
  { path: "/register", component: () => import("@/views/login/SignUp") },
  {
    path: "/invalid-link",
    component: () => import("@/views/login/InvalidLinkPage"),
  },
  {
    path: "/",
    component: () => import("@/views/Home"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: "/tools",
      },
      {
        path: "tools",
        component: () => import("@/views/tools"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "tools/id/:id",
        component: () => import("@/views/tools/AbsToolInfo"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/settings",
    component: () => import("@/views/settings"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "/settings/account",
      },
      {
        path: "account",
        component: () => import("@/views/settings/account"),
      },
      {
        path: "users",
        component: () => import("@/views/settings/users"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (!requiresAuth) return next();

  const currentUser = firebase.auth().currentUser;

  if (!currentUser) return next("/login");

  next();
});

export default router;
