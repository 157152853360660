import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import axios from "@/axios.js";

export default new Vuex.Store({
  state: {
    tools: [],
    loading: false,
  },
  getters: {},
  mutations: {
    setTools(state, tools) {
      state.tools = tools;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async setTools(context) {
      context.commit("setLoading", true);
      const { data } = await axios.get("/api/tools");
      context.commit("setTools", data);
      context.commit("setLoading", false);
    },
  },
  modules: {},
});
