import axios from "axios";
import router from "./router";

import firebase from "firebase/compat/app";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.response.status);
    if (error.response.status === 401 || error.response.status === 403) {
      return router.replace("/logout");
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (config) {
    return new Promise(function (resolve) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          config.headers.Authorization = `Bearer ${idToken}`;
          resolve(config);
        });
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
