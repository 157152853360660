import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";

import "./assets/main.scss";

Vue.config.productionTip = false;

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
let app = null;

import firebaseConfig from "@/firebase/firebaseConfig";

firebase.initializeApp(firebaseConfig);

Vue.mixin({
  methods: {
    parsedDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    capitalize(string) {
      if (!string) return;
      const array = string.split(" ");
      const newArray = [];
      array.forEach((element) => {
        newArray.push(element.charAt(0).toUpperCase() + element.slice(1));
      });
      return newArray.join(" ");
    },
  },
  computed: {
    myFooterProps() {
      return {
        "items-per-page-options": [50, 100, 500, -1],
        "show-first-last-page": true,
      };
    },
  },
});

firebase.auth().onAuthStateChanged((user) => {
  console.dir(user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: function (h) {
        return h(App);
      },
    }).$mount("#app");
  }
});
